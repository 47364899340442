// import 'regenerator-runtime/runtime';
import "babel-polyfill";

const show = async function (response) {
    const data = await response.json();
    if (data && data.data && `${data.data}`.match(/^[0-9]+$/i)) {
        // console.log("0res==", data.data);
        document.querySelector("#jxnum").innerText = data.data;
    }
}
const jx = function () {
    fetch("/counter.php", {
            method: "POST",
            body: JSON.stringify({
                add: 1,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then(async (response) => {
            // console.log("res==", response);
            await show(response);
        })
        .catch(async (err) => {
            // console.log("res==err", err);
        });
};
const js = function () {
    fetch("/counter.php", {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then(async (response) => {
            await show(response);
        })
        .catch(async (err) => {
            // console.log("0res==err", err);
        });
};
js();
let isplay = false;
document.querySelector("#link_id").addEventListener("click", function () {
    isplay = !isplay;
    if (isplay) {
        jx();
        document.querySelector("#linkAudio").play();
        document.querySelector("#jp_id").style.display = '';
        document.querySelector("#sx_id").style.display = '';
    } else {
        document.querySelector("#linkAudio").pause();
        document.querySelector("#jp_id").style.display = 'none';
        document.querySelector("#sx_id").style.display = 'none';
    }
});
document.querySelector("#linkAudio").addEventListener("ended", function (e) {
    isplay = false;
    // document.querySelector("#linkAudio").pause();
    document.querySelector("#jp_id").style.display = 'none';
    document.querySelector("#sx_id").style.display = 'none';
}, false);

